<template>
  <div class="table-responsive">

    <DataTable :value="applications" paginator :rows="25" :globalFilterFields="['barcode', 'last_name','first_name','middle_name']"
               v-model:filters="filters">
      <template #header>
        <div class="p-d-flex p-jc-between p-ai-center">
              <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Поиск студента"/>
              </span>
        </div>
      </template>
      <template #empty>
        Студент не найден
      </template>
      <Column header="№" :style="{width: '60px'}">
        <template #body="{index}">
          {{index+1}}
        </template>
      </Column>
      <Column field="barcode" header="Баркод"></Column>
      <Column header="ФИО">
        <template #body="{data}">
          {{data?.last_name}} {{data?.first_name}} {{data?.middle_name}}
        </template>
      </Column>
      <Column header="ОП/Уровень/Группа">
        <template #body="{data}">{{data?.education_speciality_name}}/{{data?.study_level_name}}/{{data?.group_name}}
        </template>
      </Column>
      <Column field="reason_text" header="Причина"></Column>

      <Column header="Предметы" style="width: 500px">
        <template #body="{data}">
          <DataTable :value="data.disciplines">
            <Column field="discipline_name" header="Предмет"></Column>
            <Column field="credit" header="Кредит ects"></Column>
            <Column header="Сумма">
              <template #body="{data}">
                {{Math.round(data.sum)}}
              </template>
            </Column>

            <ColumnGroup type="footer">
              <Row>
                <Column footer="Всего:" footerStyle="text-align:right" />
                <Column :footer="data.disciplines.reduce((sum, d)=>+d.credit+sum, 0)" />
                <Column :footer="data.disciplines.reduce((sum, d)=>Math.round(+d.sum)+sum, 0)" />
              </Row>
            </ColumnGroup>

          </DataTable>
        </template>
      </Column>
      <Column field="language" header="Язык"></Column>
      <Column field="phone" header="Телефон">

      </Column>
      <Column header="Дата заявки">
        <template #body="{data}">
          {{convertTimestampToDate(data.created_at)}}
        </template>
      </Column>

      <Column header="Квитанция об оплате">
        <template #body="{data}">
          <div v-if="data?.file">
            <a :href="`https://back.uib.kz/uploads/${data.file}`" target="_blank">
              Квитанция
            </a>
          </div>
          <div v-else>Не прикреплен</div>
        </template>
      </Column>
      <Column header="Действия">
        <template #body="{data}">
          <div class="my-1">
            <Button label="Вернуть в отправленные" class="p-button-success" :disabled="changingStatus"
                    @click="changeApplicationStatus(data.summer_request_id, 0)"/>
          </div>
        </template>
      </Column>
    </DataTable>

    <table class="table table-bordered">
      <thead>
      <tr>
        <th>№</th>
        <th>Баркод</th>
        <th>ФИО</th>
        <th>ОП/Уровень/Группа</th>
        <th>Причина</th>
        <th>Предметы</th>
        <th>Язык</th>
        <th>Телефон</th>
        <th>Дата заявки</th>
        <th>Квитанция об оплате</th>
        <th>Действия</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(application, applicationIndex) in applications"
          :key="applicationIndex">
        <td>{{applicationIndex+1}}</td>
        <td>{{application.barcode}}</td>
        <td>{{application?.last_name}} {{application?.first_name}} {{application?.middle_name}}</td>
        <td>
          {{application?.education_speciality_name}}/{{application?.study_level_name}}/{{application?.group_name}}
        </td>
        <td>{{application.reason_text}}</td>
        <td>
          <table class="table table-sm">
            <tbody>
            <tr>
              <th>Предметы</th>
              <th>Кредит ects</th>
              <th>Сумма</th>
            </tr>
            <tr v-for="(discipline, disciplineIndex) in application.disciplines" :key="disciplineIndex">
              <td>{{discipline.discipline_name}}</td>
              <td>{{discipline.credit}}</td>
              <td>{{Math.round(discipline.sum)}}</td>
            </tr>
            <tr>
              <th>Всего</th>
              <th>{{application.disciplines.reduce((sum, d)=>+d.credit+sum, 0)}}</th>
              <th>{{application.disciplines.reduce((sum, d)=>Math.round(+d.sum)+sum, 0)}}</th>
            </tr>
            </tbody>
          </table>
        </td>
        <td>{{application.language}}</td>
        <td>{{application.phone}}</td>
        <td>{{convertTimestampToDate(application.created_at)}}</td>
        <td>
          <div v-if="application?.file">
            <a :href="`https://back.uib.kz/uploads/${application.file}`" target="_blank">
              Квитанция
            </a>
          </div>
          <div v-else>Не прикреплен</div>
        </td>
        <td>
          <div class="my-1">
            <Button label="Вернуть в отправленные" class="p-button-success" :disabled="changingStatus"
                    @click="changeApplicationStatus(application.summer_request_id, 0)"/>
          </div>
        </td>

      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import {FilterMatchMode, FilterOperator} from "primevue/api";

export default {
    name: "ApplicationsRejectedAcademicDebt",
    props: ['applications', 'changeApplicationStatus', 'convertTimestampToDate'],
  data() {
    return {

      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'barcode': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'last_name': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'first_name': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'middle_name': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
      },
    }
  }
  }
</script>

<style scoped>

</style>