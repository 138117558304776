<template>
  <div class="table-responsive">


    <DataTable :value="applications" paginator :rows="25" :globalFilterFields="['barcode', 'last_name','first_name','middle_name']"
               v-model:filters="filters">
      <template #header>
        <div class="p-d-flex p-jc-between p-ai-center">
              <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Поиск студента"/>
              </span>
        </div>
      </template>
      <Column header="№" :style="{width: '60px'}">
        <template #body="{index}">
          {{index+1}}
        </template>
      </Column>
      <Column field="barcode" header="Баркод"></Column>
      <Column header="ФИО">
        <template #body="{data}">
          {{data?.last_name}} {{data?.first_name}} {{data?.middle_name}}
        </template>
      </Column>
      <Column header="ОП/Уровень/Группа">
        <template #body="{data}">{{data?.education_speciality_name}}/{{data?.study_level_name}}/{{data?.group_name}}
        </template>
      </Column>
      <Column field="reason_text" header="Причина"></Column>
      <Column field="language" header="Язык"></Column>
      <Column field="phone" header="Телефон">

      </Column>
      <Column header="Дата заявки">
        <template #body="{data}">
          {{convertTimestampToDate(data.created_at)}}
        </template>
      </Column>
      <Column header="Предметы" style="width: 700px">
        <template #body="{data}">
          <DataTable :value="data.disciplines">
            <Column field="discipline_name" header="Предмет"></Column>
            <Column field="credit" header="Кредит ects"></Column>
            <Column field="department" header="Кафедра"></Column>
            <Column header="Преподаватель">
              <template #body="{data}">
                {{data?.pps_lastname}} {{data?.pps_firstname}} {{data?.pps_middlename}}
              </template>
            </Column>
          </DataTable>
        </template>
      </Column>
    </DataTable>



<!--    <table class="table table-bordered">-->
<!--      <thead>-->
<!--      <tr>-->
<!--        <th>№</th>-->
<!--        <th>Баркод</th>-->
<!--        <th>ФИО</th>-->
<!--        <th>ОП/Уровень/Группа</th>-->
<!--        <th>Причина</th>-->
<!--        <th>Язык</th>-->
<!--        <th>Телефон</th>-->
<!--        <th>Дата заявки</th>-->
<!--        <th>Предметы</th>-->
<!--      </tr>-->
<!--      </thead>-->
<!--      <tbody>-->
<!--      <tr v-for="(application, applicationIndex) in applications"-->
<!--          :key="applicationIndex">-->
<!--        <td>{{applicationIndex+1}}</td>-->
<!--        <td>{{application.barcode}}</td>-->
<!--        <td>{{application?.last_name}} {{application?.first_name}} {{application?.middle_name}}</td>-->
<!--        <td>-->
<!--          {{application?.education_speciality_name}}/{{application?.study_level_name}}/{{application?.group_name}}-->
<!--        </td>-->
<!--        <td>{{application.reason_text}}</td>-->
<!--        <td>{{application.language}}</td>-->
<!--        <td>{{application.phone}}</td>-->
<!--        <td>{{convertTimestampToDate(application.created_at)}}</td>-->
<!--        <td>-->
<!--          <table class="table table-sm">-->
<!--            <tbody>-->
<!--            <tr>-->
<!--              <th>Предметы</th>-->
<!--              <th>Кредит ects</th>-->
<!--              <th>Кафедра</th>-->
<!--              <th>Преподаватель</th>-->
<!--            </tr>-->
<!--            <tr v-for="(discipline, disciplineIndex) in application.disciplines" :key="disciplineIndex">-->
<!--              <td>{{discipline.discipline_name}}</td>-->
<!--              <td>{{discipline.credit}}</td>-->
<!--              <td>{{discipline.department}}</td>-->
<!--              <td>-->
<!--                {{discipline?.pps_lastname}} {{discipline?.pps_firstname}} {{discipline?.pps_middlename}}-->
<!--              </td>-->
<!--            </tr>-->
<!--            </tbody>-->
<!--          </table>-->
<!--        </td>-->
<!--      </tr>-->
<!--      </tbody>-->
<!--    </table>-->
  </div>
</template>

<script>

import {FilterMatchMode, FilterOperator} from "primevue/api";

export default {
    name: "ApplicationsConfirmedAcademicDebt",
    props: ['applications', 'convertTimestampToDate'],

    data() {
      return {

        filters: {
          'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
          'barcode': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
          },
          'last_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
          },
          'first_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
          },
          'middle_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
          },
        },
      }
    }
  }
</script>

<style scoped>

</style>
