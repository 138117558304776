<template>
  <div class="container-fluid p-3">

    <PrimePreloader v-if="loading"/>

    <div v-else>
      <h5 class="m-3">Заявления на академ задолженности</h5>
      <TabView>
        <TabPanel header="Отправленные">
          <ApplicationsSentAcademicDebt
              :applications="academicDebtService.applications.filter(i=>i.status == 0)"
              :changeApplicationStatus="changeApplicationStatus"
              :convertTimestampToDate="convertGraduateWorkTimestampToDate"/>
        </TabPanel>
        <TabPanel header="Подтвержденные">
          <ApplicationsConfirmedAcademicDebt
              :applications="academicDebtService.applications.filter(i=>i.status == 1)"
              :convertTimestampToDate="convertGraduateWorkTimestampToDate"/>
        </TabPanel>
        <TabPanel header="Отказанные">
          <ApplicationsRejectedAcademicDebt
              :applications="academicDebtService.applications.filter(i=>i.status == 2)"
              :changeApplicationStatus="changeApplicationStatus"
              :convertTimestampToDate="convertGraduateWorkTimestampToDate"/>
        </TabPanel>
      </TabView>
    </div>

  </div>
</template>

<script>
  import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
  import {convertGraduateWorkTimestampToDate} from "@/utils/helpers/convertGraduateWorkTimestampToDate";
  import ApplicationsSentAcademicDebt
    from "@/components/office-registration/academic-debt-service/ApplicationsSentAcademicDebt.vue";
  import ApplicationsConfirmedAcademicDebt
    from "@/components/office-registration/academic-debt-service/ApplicationsConfirmedAcademicDebt.vue";
  import ApplicationsRejectedAcademicDebt
    from "@/components/office-registration/academic-debt-service/ApplicationsRejectedAcademicDebt.vue";

  export default {
    name: "AcademicDebtOfficeRegistration",
    components: {
      ApplicationsSentAcademicDebt,
      ApplicationsConfirmedAcademicDebt,
      ApplicationsRejectedAcademicDebt
    },
    data() {
      return {
        loading: true,
        applicationStatuses: {
          0: 'Оправлено',
          1: 'Подтверждено',
          2: 'Отклонено'
        },
        changingStatus: false
      }
    },
    computed: {
      ...mapState('academicDebtService', ['academicDebtService']),
    },
    methods: {
      ...mapActions('academicDebtService', ['GET_APPLICATIONS_ACADEMIC_DEBT', 'PUT_APPLICATION_STATUS']),
      convertGraduateWorkTimestampToDate,
      async changeApplicationStatus(id, status) {
        this.changingStatus = true
        const res = await this.PUT_APPLICATION_STATUS({id, status})
        if (res) {
          await this.GET_APPLICATIONS_ACADEMIC_DEBT()
          this.$message({text: `Успешно. Статус ${this.applicationStatuses[status]}`})
        }
        this.changingStatus = false
      }
    },
    async mounted() {
      await this.GET_APPLICATIONS_ACADEMIC_DEBT()
      this.loading = false
    }
  }
</script>

<style scoped>

</style>